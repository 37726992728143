import axios from 'axios'

export function getAxiosConfig(isAuth) {
  const options = {
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Content-type': 'application/json',
    },
  }

  if (!isAuth) return axios.create(options)

  const instance = axios.create(options)

  instance.interceptors.request.use(async function (config) {
    let token = ''
    const customerToken = localStorage.getItem('customerToken')

    if (customerToken !== null) {
      token = customerToken
    }

    if (token !== '') {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  })

  return instance
}
