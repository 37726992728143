import React from 'react'
import { paymentMethod } from 'data/paymentMethod'
import { useRecoilState } from 'recoil'
import { paymentState } from 'store/atoms'
import CC from 'assets/payment/credit-card.svg'
import VA from 'assets/payment/wallet.svg'

export default function PilihPembayaran() {
  const [selectedPayment, setSelectedPayment] = useRecoilState(paymentState)

  const onSelectPayment = (id) => {
    const payment = paymentMethod.find((f) => f.id === id)
    setSelectedPayment(payment)
  }

  return (
    <section id="pilih-pembayaran" className="bg-putih text-center pb-5">
      <h2 className="uppercase font-bold text-3xl color-coklat-muda">
        pilih pembayaran
      </h2>
      <div className="flex justify-center items-center pt-5">
        <button
          className={`flex items-center text-left p-5 rounded-lg border border-orange-300 border-2 text-white w-[435px] hover:bg-green-600 ${
            selectedPayment.id === 1 ? 'bg-green-600' : 'bg-cokelat '
          }`}
          onClick={() => onSelectPayment(1)}
        >
          <img src={CC} alt="Kartu Kredit" />
          <span className="text-lg leading-5 ml-3">
            Dapat menggunakan kartu kredit Dengan menggunakan cicilan
          </span>
        </button>
        <div className="mx-3">atau</div>
        <button
          className={`flex items-center text-left p-5 rounded-lg border border-orange-300 border-2 text-white w-[435px] hover:bg-green-600 ${
            selectedPayment.id === 2 ? 'bg-green-600' : 'bg-cokelat '
          }`}
          onClick={() => onSelectPayment(2)}
        >
          <img src={VA} alt="Virtual Account" />
          <span className="text-lg leading-5 ml-3">
            Pembayaran dengan Virtual Account, Transfer Bank, atau Dompet
            Elektronik
          </span>
        </button>
      </div>
    </section>
  )
}
