import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Discount from '../assets/images/discount.png'
import Car from '../assets/images/sedan-car.png'
import Payment from '../assets/images/payment.png'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useRecoilState } from 'recoil'
import {
  paymentState,
  selectedCarState,
  selectedPackageState,
} from 'store/atoms'
import { getGuestToken } from '../utils/api/user'
import { createOrder } from '../utils/api/order'
import { BiCalendar } from 'react-icons/bi'
import { AiOutlineClockCircle } from 'react-icons/ai'

const OrderForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [pickupTime, setPickupTime] = useState('')
  const [selectedPackage, setSelectedPackage] =
    useRecoilState(selectedPackageState)
  const [selectedCar, setSelectedCar] = useRecoilState(selectedCarState)
  const [selectedPayment, setSelectedPayment] = useRecoilState(paymentState)

  const navigate = useNavigate()

  const remove = {
    id: 0,
    name: '',
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const onSubmit = async (data) => {
    setIsSubmitting(true)

    const dataUser = Object.assign(data, {
      pickUpTime: pickupTime.toLocaleTimeString(),
      rentDate: startDate,
      orderDetail: [...[selectedPackage], ...[selectedCar]],
      payment: selectedPayment,
    })

    const response = await getGuestToken()
    if (response.status !== 200) {
      setIsSubmitting(false)
      return
    }

    const { token } = response.data
    localStorage.setItem('customerToken', token)

    const responseOrder = await createOrder(dataUser)
    if (responseOrder.status !== 200) {
      setIsSubmitting(false)
      return
    }

    // reset form
    setStartDate('')
    setPickupTime('')
    reset({ name: '', phone: '' })
    setSelectedPackage(remove)
    setSelectedCar(remove)
    setSelectedPayment(remove)
    setIsSubmitting(false)

    let packages = ''
    let car = ''
    let payment = ''

    if (selectedPackage.id !== 0) {
      packages = `(${selectedPackage.name})`
    }

    if (selectedCar.id !== 0) {
      car = `(${selectedCar.name})`
    }

    if (selectedPayment.id !== 0) {
      payment = `(${selectedPayment.name})`
    }

    navigate('/thanks-for-order')

    setTimeout(() => {
      window.open(
        `https://wa.me/628113978995?text=Hi Praya, i need more information about travel agency |
        Rent car with details: ${packages} | ${car} | ${payment} | (PICK UP: ${pickupTime.toLocaleTimeString()} - ${startDate.toLocaleDateString()})`,
        '_blank'
      )
    }, 2000)
  }

  const handleDelete = (type) => {
    if (type === 'package') {
      setSelectedPackage(remove)
    }

    if (type === 'car') {
      setSelectedCar(remove)
    }

    if (type === 'payment') {
      setSelectedPayment(remove)
    }
  }

  return (
    <section className="bg-putih pb-20">
      <div className="lg:w-3/4 mx-auto text-center p-5">
        <h2 className="text-xl font-bold relative text-stone-500 uppercase tracking-widest">
          Order Data
        </h2>
        <div className="w-[80px] mx-auto pt-3">
          <hr className="border-stone-500" />
        </div>

        <div className="summary flex space-x-2 my-5">
          {selectedPackage.id !== 0 && (
            <div className="relative bg-white rounded-lg border w-full border-green-500">
              <button
                className="absolute top-2 right-3 z-1 text-xl"
                onClick={() => handleDelete('package')}
              >
                X
              </button>
              <div className="flex flex-col items-center p-5">
                <img src={Discount} alt="discount" className="w-8" />
                <p className="py-1">Special Promo</p>
                <h3 className="text-xl font-bold">{selectedPackage.name}</h3>
              </div>
            </div>
          )}
          {selectedCar.id !== 0 && (
            <div className="relative bg-white rounded-lg border w-full border-green-500">
              <button
                className="absolute top-2 right-3 z-1 text-xl"
                onClick={() => handleDelete('car')}
              >
                X
              </button>
              <div className="flex flex-col items-center p-5">
                <img src={Car} alt="car" className="w-8" />
                <p className="py-1">Car Rent Option</p>
                <h3 className="text-xl font-bold">{selectedCar.name}</h3>
              </div>
            </div>
          )}
          {selectedPayment.id !== 0 && (
            <div className="relative bg-white rounded-lg border w-full border-green-500">
              <button
                className="absolute top-2 right-3 z-1 text-xl"
                onClick={() => handleDelete('payment')}
              >
                X
              </button>
              <div className="flex flex-col items-center p-5">
                <img src={Payment} alt="car" className="w-8" />
                <p className="py-1">Payment</p>
                <h3 className="text-xl font-bold">{selectedPayment.name}</h3>
              </div>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-2 flex gap-5">
            <div className="w-full relative">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Date"
                className="w-full p-3 border text-base"
                minDate={new Date()}
                required
              />
              <div className="text-left text-red-500">
                {errors.datetime && <span>This field is required</span>}
              </div>
              <div className="absolute top-5 right-3 z-[10]">
                <BiCalendar size={20} color="#ccc" />
              </div>
            </div>
            <div className="w-full relative">
              <DatePicker
                selected={pickupTime}
                onChange={(t) => setPickupTime(t)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                placeholderText="Pick Up Time"
                className="w-full p-3 border text-base"
                required
              />
              <div className="text-left text-red-500">
                {errors.time && <span>This field is required</span>}
              </div>
              <div className="absolute top-5 right-3 z-[10]">
                <AiOutlineClockCircle size={20} color="#ccc" />
              </div>
            </div>
          </div>
          <div className="py-2">
            <input
              {...register('name', { required: true })}
              placeholder="Name"
              className="w-full p-3 border text-base"
            />
            <div className="text-left text-red-500">
              {errors.name && <span>This field is required</span>}
            </div>
          </div>
          <div className="py-2">
            <input
              {...register('phone', { required: true })}
              placeholder="No. Whatsapp"
              className="w-full p-3 border text-base"
            />
            <div className="text-left text-red-500">
              {errors.phone && <span>This field is required</span>}
            </div>
          </div>
          <nav className="py-5">
            <button
              type="submit"
              className="btn-car-order py-1 px-5 text-white rounded text-lg"
            >
              {isSubmitting ? 'Booking in progress ...' : 'Book Now'}
            </button>
          </nav>
        </form>
      </div>
    </section>
  )
}

export default OrderForm
