import React from 'react'
import ThumbSvg from 'assets/images/thumb.svg'

const Layanan = () => (
  <section className="layanan bg-cokelat">
    <div className="list">
      <div className="icon">
        <img src={ThumbSvg} alt="thumb" />
      </div>
      <div className="label">Layanan 24 jam</div>
    </div>
    <div className="list">
      <div className="icon">
        <img src={ThumbSvg} alt="thumb" />
      </div>
      <div className="label">Tanpa biaya administrasi*</div>
    </div>
    <div className="list">
      <div className="icon">
        <img src={ThumbSvg} alt="thumb" />
      </div>
      <div className="label">Tanpa biaya pengantaran*</div>
    </div>
  </section>
)

export default Layanan
