import InnovaReborn from '../assets/car/innova-reborn-v.jpg'
import InnovaVenturer from '../assets/car/innova-venturer.jpg'
import AlphardTransformer from '../assets/car/alphard-transfomer.jpg'
import AlphardVellfire from '../assets/car/alphard-vellfire.jpg'
import Pajero42 from '../assets/car/pajero4x2.jpg'
import FortunerVRZ from '../assets/car/fortuner-vrz.jpg'
import HiaceCommuter from '../assets/car/hiace-commuter.jpg'
import HiacePremio from '../assets/car/hiace-premio.jpg'
import HummerH3 from '../assets/car/hummer-h3.jpg'
import MiniCooper from '../assets/car/mini.jpg'

export const kategoriMobil = ['Middle MPV', 'Luxury MPV', 'SUV']
export const sampleDataMobil = [
  {
    id: 1,
    merek: 'Innova Reborn Type G (2018-2021)',
    harga: 'Rp. 850,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: InnovaReborn,
    kategori: 'Middle MPV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 1,200,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '7 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 2,
    merek: 'Innova Reborn Type V (2018-2021)',
    harga: 'Rp. 900,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: InnovaReborn,
    kategori: 'Middle MPV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 1,250,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '7 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 3,
    merek: 'Innova Venturrer (2018-2021)',
    harga: 'Rp. 950,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: InnovaVenturer,
    kategori: 'Middle MPV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 1,300,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '7 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 4,
    merek: 'Alphard Transfomer (2018-2021)',
    harga: 'Rp. 2,000,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: AlphardTransformer,
    kategori: 'Luxury MPV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 2,400,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '7 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 5,
    merek: 'Alphard Vellfire (2018-2021)',
    harga: 'Rp. 2,100,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: AlphardVellfire,
    kategori: 'Luxury MPV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 2,500,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '7 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 6,
    merek: 'Pajero 4x2 (2018-2021)',
    harga: 'Rp. 1,550,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: Pajero42,
    kategori: 'SUV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 1,850,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '7 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 7,
    merek: 'Fortuner VRZ (2018-2022)',
    harga: 'Rp. 1,500,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: FortunerVRZ,
    kategori: 'SUV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 1,800,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '7 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 8,
    merek: 'Hiace Commuter (2018-2021)',
    harga: 'Rp. 1,400,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: HiaceCommuter,
    kategori: 'Middle MPV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 1,700,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '16 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 9,
    merek: 'Hiace Premio (2018-2021)',
    harga: 'Rp. 1,600,000',
    keterangan:
      '12jam/include BBM & driver/include Mineral Water/Free Travel Arrangement*',
    foto: HiacePremio,
    kategori: 'Luxury MPV',
    drive: '',
    detail: [
      {
        id: 1,
        label: 'Rp. 1,900,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Monthly Rent',
        keterangan:
          'Include driver or exclude driver, include BBM or exclude BBM',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '16 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 10,
    merek: 'Hummer H3 (2018-2022)',
    harga: 'Rp. 4,800,000',
    keterangan:
      'include BBM & driver/include Mineral Water follow the terms of Self Drive*',
    foto: HummerH3,
    kategori: 'SUV',
    drive: '(Self Drive 24Jam)',
    detail: [
      {
        id: 1,
        label: 'Rp. 5,000,000',
        keterangan:
          '12jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Rp. 5,300,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '7 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
  {
    id: 11,
    merek: 'Mini Cooper Countryman (2018-2022)',
    harga: 'Rp. 4,600,000',
    keterangan:
      'include BBM & driver/include Mineral Water follow the terms of Self Drive*',
    foto: MiniCooper,
    kategori: 'SUV',
    drive: '(Self Drive 24Jam)',
    detail: [
      {
        id: 1,
        label: 'Rp. 4,200,000',
        keterangan:
          '12jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
      {
        id: 2,
        label: 'Rp. Rp. 5,100,000',
        keterangan:
          '24jam/include BBM & driver/include Mineral Water Free Travel Arrangement*',
      },
    ],
    fast_track: [
      {
        id: 1,
        label: 'Airport Assitance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
      {
        id: 2,
        label: 'Road Assistance',
        keterangan: 'Confirm to Customer Service for More Information',
      },
    ],
    feature: [
      '4 Passengers (include driver)',
      'Comfort and Convenience',
      'Safety Passenger Airbag',
      'Engine Immobilizer',
      'Bluetooth Connectivity',
    ],
  },
].map((link) => {
  link.key = `nav-link-${link.merek}`
  return link
})
