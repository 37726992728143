import React from 'react'
import Bintang from '../assets/images/bintang.jpg'
import TestimoniBanner from '../assets/images/testimoni.jpg'
import { data } from '../data/testimoni'
// import { useWindowSize } from "../utils/hooks";
// import Slider from 'react-slick'

const Testimoni = () => {
  // const { width } = useWindowSize()
  // const settings = {
  //   dots: true,
  //   className: 'center',
  //   centerPadding: '35px',
  //   centerMode: true,
  //   infinite: true,
  //   slidesToShow: 1,
  //   speed: 500,
  //   arrows: false,
  // }

  const TestimoniDesktop = () => (
    <div className="grid gap-10 lg:grid-cols-3 text-left">
      {data.map((value, index) => (
        <div className="item p-6 bg-white" key={index}>
          <p className="text-stone-500 text-base leading-5">{value.pesan}</p>
          <div className="bintang py-5">
            <img src={Bintang} alt="Bintang" />
          </div>
          <div className="profile">
            <div className="foto">
              <img src={value.foto} alt="Foto" />
            </div>
            <div className="">
              <div className="font-bold text-base text-stone-500">
                {value.nama}
              </div>
              <div className="profesi text-stone-500">{value.profesi}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  // const TestimoniMobile = () => (
  //   <div className="list">
  //     <Slider {...settings}>
  //       {data.map((value, index) => (
  //         <div className="item" key={index}>
  //           <p>{value.pesan}</p>
  //           <div className="bintang">
  //             <img src={Bintang} alt="Bintang" />
  //           </div>
  //           <div className="profile">
  //             <div className="foto">
  //               <img src={value.foto} alt="Foto" />
  //             </div>
  //             <div className="user">
  //               <span className="nama">{value.nama}</span>
  //               <span className="profesi">{value.profesi}</span>
  //             </div>
  //           </div>
  //         </div>
  //       ))}
  //     </Slider>
  //   </div>
  // )

  return (
    <section className="testimoni">
      <div className="banner">
        <img src={TestimoniBanner} alt="Testimoni" />
      </div>
      <div className="description">
        <h3>TESTIMONIALS</h3>
        <hr />
        <h4>What Our Clients Say</h4>
        <div className="container">
          <TestimoniDesktop />
          {/* {width > 1000 ? <TestimoniDesktop /> : <TestimoniMobile />} */}
        </div>
      </div>
    </section>
  )
}

export default Testimoni
