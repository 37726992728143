import Amara from '../assets/testimoni/amara.png'
import Nadya from '../assets/testimoni/nadya.png'
import Dinda from '../assets/testimoni/dinda.jpg'

export const data = [
  {
    id: 1,
    nama: 'Amara Sophie',
    foto: Amara,
    profesi: 'Tokoh Publik',
    pesan:
      'Kerenss banget buat service dari praya dan drivernya yg very recommended. Mobil selalu bersih, pengemudi ramah, disiplin on time dan sangat membantu. All thumbs up for Praya dan semoga semakin sukses.',
  },
  {
    id: 2,
    nama: 'Nadya Shakira',
    foto: Nadya,
    profesi: 'Influencer',
    pesan:
      'Sudah beberapa kali menggunakan jasa Praya dan selalu puas dg pelayanannya..Proses order lancar dan mudah, kondisi kendaraan sangat baik, driver juga ramah dan cara berkendara aman. Very recommended',
  },
  {
    id: 3,
    nama: 'Dinda Kirana',
    foto: Dinda,
    profesi: 'Tokoh Publik',
    pesan:
      'Terimakasih Praya, aku pasti rekomendasikan ke teman2 kalo mau liburan di bali dan butuh sewa premium car harus dari Praya. Aku pun nanti ke bali lagi pasti gunain Praya.',
  },
]
