// @flow
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import Header from 'components/Layouts/Header'
import Footer from 'components/Layouts/Footer'
import OrderMessage from 'views/order/OrderMessage'
import Home from 'views/home/HomePage'

function App() {
  return (
    <RecoilRoot>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/thanks-for-order" element={<OrderMessage />} />
      </Routes>
      <Footer />
    </RecoilRoot>
  )
}

export default App
