import LuxuryRentCar from '../assets/services/luxury-rent-carpng.png'
import FastTrack from '../assets/services/fast-track.jpg'
import TravelArrangement from '../assets/services/travel-arrangement.jpg'
import CorporateTravel from '../assets/services/coorporate-travel-management.jpg'
import BusinessTravel from '../assets/services/business-travel.jpg'
import TravelDocument from '../assets/services/travel-document.jpg'
import HolyTravel from '../assets/services/holy-travel.jpg'
import HotelReservation from '../assets/services/hotel-reservation.jpg'
import DriverOutsourcing from '../assets/services/driver-outsourching.jpg'

export const serviceList = [
  {
    id: 1,
    foto: LuxuryRentCar,
    title: 'Luxury Rent Car',
  },
  {
    id: 2,
    foto: FastTrack,
    title: 'Fast Track (Vooridjer & Airport Asisstance)',
  },
  {
    id: 3,
    foto: TravelArrangement,
    title: 'Travel Arrangement (Non Corporate',
  },
  {
    id: 4,
    foto: CorporateTravel,
    title: 'Corporate Travel Management & MICE',
  },
  {
    id: 5,
    foto: BusinessTravel,
    title: 'Business Travel Insurance',
  },
  {
    id: 6,
    foto: TravelDocument,
    title: 'Travel Document Arrangement (Jasa Pengurusan VISA)',
  },
  {
    id: 7,
    foto: HolyTravel,
    title: 'Holy Travel',
  },
  {
    id: 8,
    foto: HotelReservation,
    title: 'Hotel Reservation',
  },
  {
    id: 9,
    foto: DriverOutsourcing,
    title: 'Driver Outsourcing',
  },
]
