import React, { useState } from 'react'
import { kategoriMobil, sampleDataMobil } from 'data/mobil'
import { useRecoilState } from 'recoil'
import { selectedCarState } from 'store/atoms'

const ModalDetailMobil = ({
  isModalOpen,
  detailMobil,
  detailTab,
  onHandleMoreDetail,
  onHandleModal,
}) => {
  if (!isModalOpen) return <></>
  return (
    <div className="modal-detail-mobil">
      <div className="content">
        <nav className="close">
          <button onClick={() => onHandleModal()}>X</button>
        </nav>
        <div className="detail">
          <div className="item-q">
            <div className="foto">
              <img src={detailMobil.foto} alt="Mobil Pajero" />
            </div>
            <div className="desc">
              <span className="merek">{detailMobil.merek}</span>
              <span className="label-harga">
                Harga <span className="drive">{detailMobil.drive}</span>
              </span>
              <span className="harga">{detailMobil.harga}</span>
              <p>{detailMobil.keterangan}</p>
            </div>
          </div>
          <div className="item-d">
            <div className="detail-feature">
              {detailTab === 'detail' && (
                <div className="harga">
                  <h3>More Detail</h3>
                  <ul>
                    {detailMobil.detail &&
                      detailMobil.detail.map((value, index) => (
                        <li key={`detail-${value.id}`}>
                          <h4>{value.label}</h4>
                          <p>{value.keterangan}</p>
                        </li>
                      ))}
                    <li>
                      <h4>Fast Track</h4>
                      <ol>
                        {detailMobil.fast_track &&
                          detailMobil.fast_track?.map((value, index) => (
                            <li key={`ff-${value.id}`}>
                              <h5>{value.label}</h5>
                              <p>{value.keterangan}</p>
                            </li>
                          ))}
                      </ol>
                    </li>
                  </ul>
                </div>
              )}

              {detailTab === 'feature' && (
                <div className="feature">
                  <h3>Feature Program</h3>
                  <ul>
                    {detailMobil?.feature.map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <nav>
              {detailTab === 'detail' ? (
                <button
                  onClick={() => onHandleMoreDetail(detailMobil.id, 'feature')}
                >
                  Feature
                </button>
              ) : (
                <button
                  onClick={() => onHandleMoreDetail(detailMobil.id, 'detail')}
                >
                  More Detail
                </button>
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

const CarList = () => {
  const [dataMobil, setDataMobil] = useState(sampleDataMobil)
  const [detailMobil, setDetailMobil] = useState(sampleDataMobil[0])
  const [detailTab, setDetailTab] = useState('detail')
  const [kategoriTerpilih, setKategoriTerpilih] = useState('0')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCar, setSelectedCar] = useRecoilState(selectedCarState)
  const dataKategoriMobil = kategoriMobil

  const handleFilterMobil = (k) => {
    setKategoriTerpilih(k)
    if (k === '0') {
      setDataMobil(sampleDataMobil)
      return
    }
    const filtered = sampleDataMobil.filter((car) => car.kategori === k)
    setDataMobil(filtered)
  }

  const handleMoreDetail = (id, tab = 'detail') => {
    const detail = sampleDataMobil.find((f) => f.id === id)
    setIsModalOpen(true)
    setDetailMobil(detail)
    setDetailTab(tab)
    console.log('handleMoreDetail', id, tab)
  }

  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleOrder = (id, name) => {
    setSelectedCar({ id, name })
  }

  return (
    <section className="car-list bg-abuabu">
      <div className="container">
        <div className="car-list-l2">
          <h2 id="rent-car-options">Car Rental Options</h2>
          <nav className="category">
            <button
              onClick={() => handleFilterMobil('0')}
              className={`${kategoriTerpilih === '0' && 'active'}`}
            >
              All
            </button>
            {dataKategoriMobil?.map((value, index) => {
              const active = kategoriTerpilih === value ? 'active' : ''
              return (
                <button
                  key={index}
                  onClick={() => handleFilterMobil(value)}
                  className={active}
                >
                  {value}
                </button>
              )
            })}
          </nav>
          <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
            {dataMobil?.map((detail, index) => (
              <div className="item" key={`mobil-${index}`}>
                <div className="item-p">
                  <div className="foto">
                    <img src={detail.foto} alt="Mobil Pajero" />
                  </div>
                  <div className="desc">
                    <span className="merek">{detail.merek}</span>
                    <span className="label-harga">
                      Harga <span className="drive">{detail.drive}</span>
                    </span>
                    <span className="harga">{detail.harga}</span>
                    <p>{detail.keterangan}</p>
                  </div>
                  <nav>
                    <button onClick={() => handleMoreDetail(detail.id)}>
                      More Detail
                    </button>
                    <button
                      onClick={() => handleMoreDetail(detail.id, 'feature')}
                    >
                      Feature
                    </button>
                  </nav>
                  <button
                    className={`${
                      selectedCar.id === detail.id
                        ? 'bg-green-600'
                        : 'btn-car-order'
                    } text-white mx-3 rounded p-1 text-base hover:bg-green-600`}
                    onClick={() => handleOrder(detail.id, detail.merek)}
                  >
                    Order
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalDetailMobil
        isModalOpen={isModalOpen}
        detailMobil={detailMobil}
        detailTab={detailTab}
        onHandleMoreDetail={handleMoreDetail}
        onHandleModal={handleCloseModal}
      />
    </section>
  )
}

export default CarList
