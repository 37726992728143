import React from 'react'
import { useRecoilState } from 'recoil'
import { contactState } from 'store/atoms'
import { serviceList } from 'data/service'

const Services = () => {
  const [isModalContactShow, setIsModalContactShow] =
    useRecoilState(contactState)
  const handleModalContactShow = () => {
    setIsModalContactShow(!isModalContactShow)
  }

  return (
    <section className="services bg-abuabu" id="service">
      <div className="container">
        <div className="service-l2">
          <h2 className="heading">Services</h2>
          <ul className="flex flex-wrap justify-center text-center">
            {serviceList?.map((value, index) => (
              <li
                key={`x-${index}`}
                className="w-[150px] flex flex-col items-center m-3"
              >
                <div className="foto rounded-full overflow-hidden w-[100px] h-[100px] drop-shadow-md border-4 border-white">
                  <img
                    src={value.foto}
                    alt={value.title}
                    className="w-full h-[100px] object-cover"
                  />
                </div>
                <div className="text-base py-3">{value.title}</div>
              </li>
            ))}
          </ul>
          <nav className="mt-5">
            <button onClick={handleModalContactShow}>
              For more info contact us
            </button>
          </nav>
        </div>
      </div>
    </section>
  )
}

export default Services
