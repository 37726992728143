import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { contactState } from 'store/atoms'
import LogoPrayaBlack from 'assets/images/praya-logo-black.svg'

const Header = () => {
  const [isModalMenuShow, setIsModalMenuShow] = useState(false)
  const [isModalContactShow, setIsModalContactShow] =
    useRecoilState(contactState)
  const menuRef = useRef()

  useEffect(() => {
    document.addEventListener('click', handleCloseModalMenu)
    return () => {
      document.removeEventListener('click', handleCloseModalMenu)
    }
  }, [])

  const handleCloseModalMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsModalMenuShow(false)
    }
  }

  const handleModalContactShow = () => {
    setIsModalContactShow(!isModalContactShow)
  }

  const ModalMenu = () => {
    if (!isModalMenuShow) return <></>
    return (
      <section className="modal-menu">
        <div className="close">
          <button tabIndex="0">X</button>
        </div>
        <nav>
          <a href="/#services">Service</a>
          <a href="/#rent-car-options">Rent Car Options</a>
          <a href="/#" onClick={handleModalContactShow}>
            Contact Us
          </a>
        </nav>
      </section>
    )
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="header-l2">
            <div className="logo">
              <Link to="/">
                <img src={LogoPrayaBlack} alt="logo Praya" />
              </Link>
            </div>
            <ul className="menu">
              <li>
                <a href="/#service">Services</a>
              </li>
              <li>
                <a href="/#rent-car-options">Rent Car Options</a>
              </li>
              <li>
                <a href="/#" className="solid" onClick={handleModalContactShow}>
                  Contact Us
                </a>
              </li>
            </ul>
            <div
              className="burger"
              onClick={() => setIsModalMenuShow(!isModalMenuShow)}
              tabIndex="0"
              ref={menuRef}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
      <ModalMenu />
    </>
  )
}

export default Header
