export const paymentMethod = [
  {
    id: 1,
    name: 'Kartu Kredit',
  },
  {
    id: 2,
    name: 'Virtual Account',
  },
]
