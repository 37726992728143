import { getAxiosConfig } from '../axiosInstance'

const resolver = getAxiosConfig(false)
const url = '/v1/user'

export const getGuestToken = async () => {
  try {
    const response = await resolver.post(`${url}/guest/token`)
    return response.data
  } catch (e) {
    return e.response
  }
}
