import React from 'react'
import { Link } from 'react-router-dom'
import PrayaBG from 'assets/banner/1.jpg'
import LogoPrayaOrder from 'assets/images/praya-logo-order.svg'

export default function OrderMessage() {
  return (
    <div className="fixed top-0 left-0 z-50 w-screen h-full bg-white grid place-content-center">
      <div className="border shadow-md w-[450px] h-[550px] text-center color-coklat-muda">
        <div className="h-[183px]">
          <img src={PrayaBG} alt="praya bg" className="w-full" />
        </div>
        <h2 className="text-xl font-bold relative uppercase tracking-widest mt-10">
          Thanks for Order
        </h2>
        <div className="w-[80px] mx-auto pt-3">
          <hr className="border-stone-500" />
        </div>
        <p className="text-base my-5 px-5">
          Pastikan Anda telah mengisi Nama dan No. Whatsapp dengan benar untuk
          konfirmasi pemesanan dan pembayaran. Customer Service kami akan
          menghubungi Anda.
        </p>
        <div className="flex justify-center mb-1">
          <img src={LogoPrayaOrder} alt="LogoPrayaOrder" />
        </div>
        <nav className="pt-3">
          <Link to="/" className="color-coklat-muda">
            klik untuk kembali
          </Link>
        </nav>
      </div>
    </div>
  )
}
