import React from 'react'
import Alert from 'assets/overview/praya-alert.svg'
import BestPractice from 'assets/overview/praya-best-practice.svg'
import CustomerService from 'assets/overview/praya-customer-service.svg'
import Customer from 'assets/overview/praya-customer.svg'

const Overview = () => (
  <section className="overview bg-putih">
    <div className="container">
      <div className="overview-l2">
        <div className="about">
          <div className="about-l2">
            <div className="desc">
              <h1>Praya Rent</h1>
              <p>Your Luxury Travel Companion</p>
              <p className="wedding">
                Wedding, MICE, One way drop off antar kota, Private, Family &
                Group Tour.{' '}
              </p>
              <p>
                Perusahaan jasa layanan transportasi dan travelling terbaik
                dengan ecosystem yang memudahkan pengguna kami untuk menjalankan
                setiap aktivitasnya yang berhubungan dengan jasa transportasi
                dan travelling.
              </p>
            </div>
          </div>
        </div>
        <div className="list">
          <h2>Overview</h2>
          <ul>
            <li>
              <div className="ico">
                <img src={BestPractice} alt="Best Practice" />
              </div>
              <div className="desc">
                <h3>Praktis dan Simple</h3>
                <p>
                  Anda tidak perlu repot, cukup booking dengan chat via WhatsApp
                  dan Instagram. Pembayaran pun mudah, dari transfer bank sampai
                  pembayaran tunai.
                </p>
              </div>
            </li>
            <li>
              <div className="ico">
                <img src={CustomerService} alt="Customer Service" />
              </div>
              <div className="desc">
                <h3>Customer Care 24/7</h3>
                <p>
                  Quick response dengan menggunakan customer service dan tim CRM
                  yang akan melayani setiap kebutuhan dari pelanggan dalam
                  menggunakan jasa dari Praya Rent.
                </p>
              </div>
            </li>
            <li>
              <div className="ico">
                <img src={Customer} alt="Understanding Customer" />
              </div>
              <div className="desc">
                <h3>Understanding Customer</h3>
                <p>
                  Menyediakan pelayanan jasa yang dapat disesuaikan dengan
                  kebutuhan customer atau pelanggan dalam hal trip arrangement.
                  Dengan tim yang professional akan membantu setiap perjalanan
                  demi mencapai pelayanan dan kenyamanan yang terbaik dengan
                  standard prosedur terbaik.
                </p>
              </div>
            </li>
            <li>
              <div className="ico">
                <img src={Alert} alt="Emergency Road Assistance" />
              </div>
              <div className="desc">
                <h3>Emergency Road Assistance</h3>
                <p>
                  Bantuan darurat untuk pengguna/pelanggan dijalan dan menjamin
                  pengguna untuk unit penggantian apabila unit yang digunakan
                  mengalami kendala.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default Overview
