import React, { useRef, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { contactState } from 'store/atoms'
import PrayaBG from '../../assets/banner/1.jpg'
import Services from '../../components/Services'
import CarList from '../../components/CarList'
import Layanan from '../../components/Layanan'
import WA from 'assets/contact/wa.png'
import IG from '../../assets/contact/ig.png'
import Overview from 'components/Overview'
import Testimoni from '../../components/Testimoni'
import OrderForm from '../../components/OrderForm'
// import SpecialPromo from './SpecialPromo'
import PilihPembayaran from '../../components/PilihPembayaran'

const Home = () => {
  const [isModalContact, setIsModalContact] = useRecoilState(contactState)
  const [isShowTestimoni, setIsShowTestimoni] = useState(false)
  const [isShowService, setIsShowService] = useState(false)
  const testimoniRef = useRef()
  const serviceRef = useRef()

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  }

  useEffect(() => {
    console.log('anya')

    const observer = new IntersectionObserver(handleObserver, options, self)
    if (testimoniRef.current) {
      observer.observe(testimoniRef.current)
    }
    if (serviceRef.current) {
      observer.observe(serviceRef.current)
    }
    return () => {
      observer.unobserve(testimoniRef.current)
      observer.unobserve(serviceRef.current)
    }
  }, [serviceRef, testimoniRef])

  const handleObserver = (entries, self) => {
    const [entry] = entries
    if (entry.target.id === 'testimoni' && entry.isIntersecting) {
      setIsShowTestimoni(true)
      self.unobserve(testimoniRef.current)
    }
    if (entry.target.id === 'service' && entry.isIntersecting) {
      setIsShowService(true)
      self.unobserve(serviceRef.current)
    }
  }

  const handleCloseContactModal = () => {
    setIsModalContact(!isModalContact)
  }

  const ModalContact = () => {
    if (!isModalContact) return <></>
    return (
      <section className="modal-contact-bg">
        <div className="modal-contact">
          <div className="close" onClick={handleCloseContactModal}>
            X
          </div>
          <div className="desc">
            <h2>Contact Us</h2>
            <p>
              Untuk pemesanan dan informasi lebih lengkap, silahkan chat ke
              kontak kami di bawah ini.
            </p>
            <nav className="wa-ig contect">
              <a href="https://wa.link/hq2j2v">
                <img src={WA} alt="WA" />
                Whatsapp
              </a>
              <a href="https://www.instagram.com/prayarent">
                <img src={IG} alt="WA" />
                Instagram
              </a>
            </nav>
            <p>Your Luxury Travel Companion.</p>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="home">
      <div className="banner">
        <img src={PrayaBG} alt="Praya Background" />
      </div>
      <Overview />
      <div id="service" ref={serviceRef}>
        {isShowService && <Services />}
      </div>
      {/* <SpecialPromo /> */}
      <CarList />
      <PilihPembayaran />
      <Layanan />
      <div id="testimoni" ref={testimoniRef}>
        {isShowTestimoni && <Testimoni />}
      </div>
      <ModalContact />
      <OrderForm />
    </section>
  )
}

export default Home
