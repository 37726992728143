import React from 'react'
import LogoPrayaWhite from 'assets/images/praya-logo-white.svg'
import WA from 'assets/contact/wa.png'
import IG from 'assets/contact/ig.png'

const Footer = () => (
  <footer id="footer">
    <div className="container">
      <div className="footer-l2">
        <div className="logo">
          <img src={LogoPrayaWhite} alt="logo praya footer" />
        </div>
        <div className="alamat">
          <h5>Bali:</h5>
          <p>
            Alamanda Office 5th Floor Jl. By Pass Ngurah Rai, Br. Kerthayasa
            No.67, Kedonganan, Kuta, Kabupaten Badung, Bali 80361
          </p>
        </div>
        <div className="alamat">
          <h5>Jakarta:</h5>
          <p>
            Menara Batavia Lantai 12A Jl. K.H. Mas Mansyur No. Kav. 126, Karet
            Tengsin, Tanah Abang, Jakarta Pusat
          </p>
        </div>
        <div className="copyrights">
          Praya Rent © Copyright {new Date().getFullYear()}. All Rights
          Reserved.
        </div>
      </div>
    </div>
    <div className="wa">
      <nav className="wa-ig">
        <a href="https://wa.link/hq2j2v">
          <img src={WA} alt="WA" width={32} height={32} />
          Whatsapp
        </a>
        <a href="https://www.instagram.com/prayarent">
          <img src={IG} alt="IG" width={32} height={32} />
          Instagram
        </a>
      </nav>
    </div>
  </footer>
)

export default Footer
