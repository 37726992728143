import { getAxiosConfig } from '../axiosInstance'

const resolver = getAxiosConfig(true)
const url = '/v1/order'

export const createOrder = async (data) => {
  try {
    const response = await resolver.post(url, data)
    return response.data
  } catch (e) {
    return e.response
  }
}
