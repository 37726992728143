import { atom } from 'recoil'

export const contactState = atom({
  key: 'contactState',
  default: false,
})

export const selectedPackageState = atom({
  key: 'selectedPackageState',
  default: {
    id: 0,
    name: '',
  },
})

export const selectedCarState = atom({
  key: 'selectedCarState',
  default: {
    id: 0,
    name: '',
  },
})

export const paymentState = atom({
  key: 'paymentState',
  default: {
    id: 0,
    name: '',
  },
})
